export default class VideoAnimation {
    constructor () {
        this._section = '.section-video'
        this._viedeo = document.getElementById('video')
        this._button = '#js-play-video'
        this._button_close = '.close-video'
        this._initAnimation()
    }

    _initAnimation () {
        $(this._button).on('click', this.handleVideoAnimation.bind(this))
        $(this._viedeo).on('click', this.handleVideoClick.bind(this))
        $(this._button_close).on('click', this.handleVideoClick.bind(this))
    }

    handleVideoAnimation () {
        $(this._section).addClass('is-active')
        this._viedeo.muted = false
        this._viedeo.pause();
        this._viedeo.currentTime = '0'
        this._viedeo.play();
        this._viedeo.requestFullscreen()
        this._viedeo.controls = true
    }

    handleVideoClick () {
        $(this._section).removeClass('is-active')
        this._viedeo.muted = true
        this._viedeo.controls = false
        this._viedeo.currentTime = '0'
        document.exitFullscreen()
    }
}