export default class ScrollToSection {
    constructor () {
        this.$href = $('[data-href]');
        this._initScroll();
    }

    _initScroll () {
        this.$href.on('click', this.handleHrefClick.bind(this))
        this.scrollToSection()
    }

    handleHrefClick (e) {
        let $this = $(e.currentTarget)
        let sectionLocation = $this.attr('data-href')
        let url = $this.parents('.nav-box').find('.nav-link a').attr('href')
        localStorage.setItem('section-location', sectionLocation)
        window.location = url
    }

    scrollToSection () {
        let activeSection = localStorage.getItem('section-location')
        if(activeSection) {
            setTimeout(() => {
                $('html, body').animate({ scrollTop: $(`#${activeSection}`).offset().top }, 1000);
                localStorage.removeItem('section-location')
            }, 1000);
        }
    }
}