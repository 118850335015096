export default class HeaderAnimation {
    constructor () {
        this.header = $('#header')
        this.$toggleNav = $('#toggle-nav')
        this._initAnimation();
    }

    _initAnimation () {
        $(window).on('scroll', () => {
            let scroll = $(window).scrollTop();
            if (scroll > 100) {
                this.header.addClass('is-active')
            } else {
                this.header.removeClass('is-active')
            }
        })
        this.$toggleNav.on('click', this.toggleNav.bind(this))
    }

    toggleNav (e) {
        let $this = $(e.currentTarget)
        this.header.toggleClass('nav-active')
        $this.toggleClass('active')
    }
}