import { init } from "aos"

export default class Popup {
    constructor() {
        this.$popupContent = $('.popup .popup-content')
        this.$popupBtn = $('.show-popup')
        this.$closePopup = $('.close-popup')
        this.$overlay = $('.overlay')
        this.init()
    }

    init() {
        this.$popupBtn.on('click', this.handleShowPopup.bind(this))
        this.$overlay.on('click', this.handleClosePopup.bind(this))
        this.$closePopup.on('click', this.handleClosePopup.bind(this))
    }

    handleShowPopup (e) {
        let data = $(e.currentTarget).attr('data-popup')
        $('.overlay, .popup').addClass('active')
        this.$popupContent.html(data)
    }

    handleClosePopup () {
        $('.overlay, .popup').removeClass('active')
    }
}