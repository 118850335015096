import { init } from 'ityped'
export default class TypeAnimation {
    constructor () {
        this.selector = '.ityped'
        this._init();
    }

    _init() {
        if(document.querySelector(this.selector)){
            let arr = document.querySelector(this.selector).getAttribute('data-itype').trim().split('|')
            let strings = arr.filter(function(e){ return e.replace(/(\r\n|\n|\r)/gm,'')});
            init(this.selector, {
                showCursor: false,
                backDelay: 1700,
                typeSpeed: 100,
                strings,
            });
        }
    }
}