export default class Accordion {
    constructor () {
        this.accordionTitle = $('.accordion-title')
        this.init();
    }

    init() {
        this.accordionTitle.on('click', this.toggleActive)
    }

    toggleActive(e) {
        let accordionItem = $(e.currentTarget);
        let accordionContent = accordionItem.next('.accordion-content')
        accordionItem.toggleClass('active')
        accordionContent.slideToggle()
    }
}
