export default class Timeline {
    constructor () {
        this.timelineTitle = $('.timeline-title');
        this.timelineItem = $('.timeline-item');
        this._initSwiper();
    }

    _initSwiper () {
        this.timelineTitle.on('click', this.toggleTimeline.bind(this))
    }

    toggleTimeline (e) {
        let $this = $(e.currentTarget)
        this.timelineItem.removeClass('is-active')
        $this.parent().addClass('is-active')
        this.startCount()
    }

    startCount () {
        $('.timeline-counter-number').each(function () {
            let $this = $(this);
            $({ Counter: 0 }).animate({ Counter: $this.attr('data-number') }, {
                duration: 4000,
                easing: 'swing',
                step: function () {
                    $this.text(Math.ceil(this.Counter));
                }
            });
        });
    }
}