import Swiper from '../../../../node_modules/swiper/swiper-bundle';
import { SERVICES_SWIPER_OPTIONS } from '../configs/swiper.config';

export default class SwiperServices {
    constructor () {
        this.testimonialsContainer = '.swiper-services';
        this._initSwiper();
    }

    _initSwiper () {
        new Swiper(this.testimonialsContainer, SERVICES_SWIPER_OPTIONS)
    }
}