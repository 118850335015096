import VideoAnimation from './modules/VideoAnimation';
import HeaderAnimation from './modules/HeaderAnimation';
import Animations from './modules/Animations';
import Accordion from './modules/Accordion';
import SwiperTestimonials from './modules/SwiperTestimonials';
import SwiperText from './modules/SwiperText';
import SwiperServices from './modules/SwiperServices';
import Counter from './modules/Counter';
import Timeline from './modules/Timeline';
import TypeAnimation from './modules/TypeAnimation';
import ScrollToSection from './modules/ScrollToSection';
import skrollr from './modules/skrollr';
import Popup from './modules/Popup';

export default class App {
    static init () {
        new VideoAnimation;
        new HeaderAnimation;
        new Animations;
        new Accordion;
        new SwiperTestimonials;
        new SwiperText;
        new SwiperServices;
        new Counter;
        new Timeline;
        new TypeAnimation;
        new ScrollToSection;
        new Popup;

        let main_video = $('#video').get(0)
        $('#js-play-button').on('click', function() {
            main_video.play()
            $(this).toggleClass('active')
            if($(this).hasClass('active')) {
                main_video.play()
                main_video.controls = true
            } else {
                main_video.pause()
                main_video.controls = false
            }
        })

        $('.facebook-link').on('click', function (e) {
            e.preventDefault();
            var facebookWindow = window.open('https://www.facebook.com/sharer/sharer.php?u=' + document.URL, 'facebook-popup', 'height=350,width=600');
            if(facebookWindow.focus) { 
                facebookWindow.focus();
            }
            return false;
        })
    
        $('.linkedin-link').on('click', function (e) {
            e.preventDefault();
            var linkedinWindow = window.open('http://www.linkedin.com/shareArticle?mini=true&url=' + document.URL, 'height=350,width=600');
            if(linkedinWindow.focus) { 
                linkedinWindow.focus();
            }
            return false;
        })
    
        $('.twitter-link').on('click', function (e) {
            e.preventDefault();
            var twitterWindow = window.open('https://twitter.com/share?url=' + document.URL, '&amp;text=Share popup on &amp;hashtags=codepen');
            if(twitterWindow.focus) { 
                twitterWindow.focus();
            }
            return false;
        })
    }
}