import Swiper from '../../../../node_modules/swiper/swiper-bundle';
import { MAIN_SWIPER_OPTIONS } from '../configs/swiper.config';

export default class SwiperText {
    constructor () {
        this.testimonialsContainer = '.swiper-text';
        this._initSwiper();
    }

    _initSwiper () {
        new Swiper(this.testimonialsContainer, MAIN_SWIPER_OPTIONS)
    }
}