import Swiper from '../../../../node_modules/swiper/swiper-bundle';
import { TESTIMONIALS_SWIPER_OPTIONS } from '../configs/swiper.config';

export default class SwiperTestimonials {
    constructor () {
        this.testimonialsContainer = '.swiper-testimonials';
        this._initSwiper();
        $('.swiper-container').on('hover', function() {
            $(this).swiper.autoplay.stop();
        }, function() {
            $(this).swiper.autoplay.start();
        });
    }

    _initSwiper () {
        new Swiper(this.testimonialsContainer, TESTIMONIALS_SWIPER_OPTIONS)
    }
}